const obfuscation = true;
const extensionsDisabled = true;
// @ts-ignore
const bareServerURL = new URL("/bare/", globalThis.location);
const github = "https://github.com/TheRealGeoDash2019/Octium";
const discord = "https://discord.gg/25NxvxyEEr";
const internalNamespace = "octium"; // original: cobalt (cobalt://, "://" omitted)
const jsNamespace = "Octium"; // original: Cobalt (eg. Cobalt.web)
const exposedInternalUrls = ["home", "blank", "settings", `${internalNamespace}-urls`, `network-error`]
if (!extensionsDisabled) {
    exposedInternalUrls.push(`extensions`)
}
// @ts-ignore
const networkErrors = {
    "-10": "ERR_ACCESS_DENIED",
    "-108": "ERR_ADDRESS_INVALID",
    "-147": "ERR_ADDRESS_IN_USE",
    "-109": "ERR_ADDRESS_UNREACHABLE",
    "-503": "ERR_ADD_USER_CERT_FAILED",
    "-122": "ERR_ALPN_NEGOTIATION_FAILED",
    "-117": "ERR_BAD_SSL_CLIENT_AUTH_CERT",
    "-22": "ERR_BLOCKED_BY_ADMINISTRATOR",
    "-20": "ERR_BLOCKED_BY_CLIENT",
    "-30": "ERR_BLOCKED_BY_CSP",
    "-32": "ERR_BLOCKED_BY_ORB",
    "-27": "ERR_BLOCKED_BY_RESPONSE",
    "-384": "ERR_CACHED_IP_ADDRESS_SPACE_BLOCKED_BY_PRIVATE_NETWORK_ACCESS_POLICY",
    "-410": "ERR_CACHE_AUTH_FAILURE_AFTER_READ",
    "-408": "ERR_CACHE_CHECKSUM_MISMATCH",
    "-407": "ERR_CACHE_CHECKSUM_READ_FAILURE",
    "-405": "ERR_CACHE_CREATE_FAILURE",
    "-412": "ERR_CACHE_DOOM_FAILURE",
    "-411": "ERR_CACHE_ENTRY_NOT_SUITABLE",
    "-409": "ERR_CACHE_LOCK_TIMEOUT",
    "-400": "ERR_CACHE_MISS",
    "-404": "ERR_CACHE_OPEN_FAILURE",
    "-413": "ERR_CACHE_OPEN_OR_CREATE_FAILURE",
    "-403": "ERR_CACHE_OPERATION_NOT_SUPPORTED",
    "-406": "ERR_CACHE_RACE",
    "-401": "ERR_CACHE_READ_FAILURE",
    "-402": "ERR_CACHE_WRITE_FAILURE",
    "-214": "ERR_CERTIFICATE_TRANSPARENCY_REQUIRED",
    "-202": "ERR_CERT_AUTHORITY_INVALID",
    "-200": "ERR_CERT_COMMON_NAME_INVALID",
    "-203": "ERR_CERT_CONTAINS_ERRORS",
    "-714": "ERR_CERT_DATABASE_CHANGED",
    "-201": "ERR_CERT_DATE_INVALID",
    "-219": "ERR_CERT_END",
    "-207": "ERR_CERT_INVALID",
    "-217": "ERR_CERT_KNOWN_INTERCEPTION_BLOCKED",
    "-212": "ERR_CERT_NAME_CONSTRAINT_VIOLATION",
    "-210": "ERR_CERT_NON_UNIQUE_NAME",
    "-204": "ERR_CERT_NO_REVOCATION_MECHANISM",
    "-206": "ERR_CERT_REVOKED",
    "-215": "ERR_CERT_SYMANTEC_LEGACY",
    "-205": "ERR_CERT_UNABLE_TO_CHECK_REVOCATION",
    "-213": "ERR_CERT_VALIDITY_TOO_LONG",
    "-716": "ERR_CERT_VERIFIER_CHANGED",
    "-211": "ERR_CERT_WEAK_KEY",
    "-208": "ERR_CERT_WEAK_SIGNATURE_ALGORITHM",
    "-29": "ERR_CLEARTEXT_NOT_PERMITTED",
    "-151": "ERR_CLIENT_AUTH_CERT_TYPE_UNSUPPORTED",
    "-103": "ERR_CONNECTION_ABORTED",
    "-100": "ERR_CONNECTION_CLOSED",
    "-104": "ERR_CONNECTION_FAILED",
    "-102": "ERR_CONNECTION_REFUSED",
    "-101": "ERR_CONNECTION_RESET",
    "-118": "ERR_CONNECTION_TIMED_OUT",
    "-330": "ERR_CONTENT_DECODING_FAILED",
    "-371": "ERR_CONTENT_DECODING_INIT_FAILED",
    "-354": "ERR_CONTENT_LENGTH_MISMATCH",
    "-26": "ERR_CONTEXT_SHUT_DOWN",
    "-171": "ERR_CT_CONSISTENCY_PROOF_PARSING_FAILED",
    "-169": "ERR_CT_STH_INCOMPLETE",
    "-168": "ERR_CT_STH_PARSING_FAILED",
    "-812": "ERR_DICTIONARY_LOAD_FAILED",
    "-301": "ERR_DISALLOWED_URL_SCHEME",
    "-804": "ERR_DNS_CACHE_MISS",
    "-800": "ERR_DNS_MALFORMED_RESPONSE",
    "-809": "ERR_DNS_NAME_HTTPS_ONLY",
    "-811": "ERR_DNS_NO_MATCHING_SUPPORTED_ALPN",
    "-810": "ERR_DNS_REQUEST_CANCELLED",
    "-805": "ERR_DNS_SEARCH_EMPTY",
    "-808": "ERR_DNS_SECURE_RESOLVER_HOSTNAME_RESOLUTION_FAILED",
    "-802": "ERR_DNS_SERVER_FAILED",
    "-801": "ERR_DNS_SERVER_REQUIRES_TCP",
    "-806": "ERR_DNS_SORT_ERROR",
    "-803": "ERR_DNS_TIMED_OUT",
    "-178": "ERR_EARLY_DATA_REJECTED",
    "-184": "ERR_ECH_FALLBACK_CERTIFICATE_INVALID",
    "-183": "ERR_ECH_NOT_NEGOTIATED",
    "-324": "ERR_EMPTY_RESPONSE",
    "-333": "ERR_ENCODING_CONVERSION_FAILED",
    "-340": "ERR_ENCODING_DETECTION_FAILED",
    "-2": "ERR_FAILED",
    "-16": "ERR_FILE_EXISTS",
    "-6": "ERR_FILE_NOT_FOUND",
    "-18": "ERR_FILE_NO_SPACE",
    "-17": "ERR_FILE_PATH_TOO_LONG",
    "-8": "ERR_FILE_TOO_BIG",
    "-19": "ERR_FILE_VIRUS_INFECTED",
    "-607": "ERR_FTP_BAD_COMMAND_SEQUENCE",
    "-606": "ERR_FTP_COMMAND_NOT_SUPPORTED",
    "-601": "ERR_FTP_FAILED",
    "-604": "ERR_FTP_FILE_BUSY",
    "-602": "ERR_FTP_SERVICE_UNAVAILABLE",
    "-605": "ERR_FTP_SYNTAX_ERROR",
    "-603": "ERR_FTP_TRANSFER_ABORTED",
    "-31": "ERR_H2_OR_QUIC_REQUIRED",
    "-119": "ERR_HOST_RESOLVER_QUEUE_TOO_LARGE",
    "-363": "ERR_HTTP2_COMPRESSION_ERROR",
    "-361": "ERR_HTTP2_FLOW_CONTROL_ERROR",
    "-362": "ERR_HTTP2_FRAME_SIZE_ERROR",
    "-360": "ERR_HTTP2_INADEQUATE_TRANSPORT_SECURITY",
    "-352": "ERR_HTTP2_PING_FAILED",
    "-337": "ERR_HTTP2_PROTOCOL_ERROR",
    "-372": "ERR_HTTP2_RST_STREAM_NO_ERROR_RECEIVED",
    "-351": "ERR_HTTP2_SERVER_REFUSED_STREAM",
    "-376": "ERR_HTTP2_STREAM_CLOSED",
    "-140": "ERR_HTTPS_PROXY_TUNNEL_RESPONSE_REDIRECT",
    "-365": "ERR_HTTP_1_1_REQUIRED",
    "-379": "ERR_HTTP_RESPONSE_CODE_FAILURE",
    "-166": "ERR_ICANN_NAME_COLLISION",
    "-705": "ERR_IMPORT_CA_CERT_FAILED",
    "-703": "ERR_IMPORT_CA_CERT_NOT_CA",
    "-704": "ERR_IMPORT_CERT_ALREADY_EXISTS",
    "-706": "ERR_IMPORT_SERVER_CERT_FAILED",
    "-355": "ERR_INCOMPLETE_CHUNKED_ENCODING",
    "-347": "ERR_INCOMPLETE_HTTP2_HEADERS",
    "-383": "ERR_INCONSISTENT_IP_ADDRESS_SPACE",
    "-501": "ERR_INSECURE_RESPONSE",
    "-12": "ERR_INSUFFICIENT_RESOURCES",
    "-106": "ERR_INTERNET_DISCONNECTED",
    "-4": "ERR_INVALID_ARGUMENT",
    "-338": "ERR_INVALID_AUTH_CREDENTIALS",
    "-321": "ERR_INVALID_CHUNKED_ENCODING",
    "-182": "ERR_INVALID_ECH_CONFIG_LIST",
    "-5": "ERR_INVALID_HANDLE",
    "-370": "ERR_INVALID_HTTP_RESPONSE",
    "-303": "ERR_INVALID_REDIRECT",
    "-320": "ERR_INVALID_RESPONSE",
    "-504": "ERR_INVALID_SIGNED_EXCHANGE",
    "-300": "ERR_INVALID_URL",
    "-505": "ERR_INVALID_WEB_BUNDLE",
    "-710": "ERR_KEY_GENERATION_FAILED",
    "-329": "ERR_MALFORMED_IDENTITY",
    "-131": "ERR_MANDATORY_PROXY_CONFIGURATION_FAILED",
    "-322": "ERR_METHOD_NOT_SUPPORTED",
    "-343": "ERR_MISCONFIGURED_AUTH_ENVIRONMENT",
    "-341": "ERR_MISSING_AUTH_CREDENTIALS",
    "-142": "ERR_MSG_TOO_BIG",
    "-105": "ERR_NAME_NOT_RESOLVED",
    "-137": "ERR_NAME_RESOLUTION_FAILED",
    "-138": "ERR_NETWORK_ACCESS_DENIED",
    "-21": "ERR_NETWORK_CHANGED",
    "-331": "ERR_NETWORK_IO_SUSPENDED",
    "-11": "ERR_NOT_IMPLEMENTED",
    "-176": "ERR_NO_BUFFER_SPACE",
    "-502": "ERR_NO_PRIVATE_KEY_FOR_CERT",
    "-112": "ERR_NO_SSL_VERSIONS_ENABLED",
    "-336": "ERR_NO_SUPPORTED_PROXIES",
    "-13": "ERR_OUT_OF_MEMORY",
    "-348": "ERR_PAC_NOT_IN_DHCP",
    "-327": "ERR_PAC_SCRIPT_FAILED",
    "-367": "ERR_PAC_SCRIPT_TERMINATED",
    "-701": "ERR_PKCS12_IMPORT_BAD_PASSWORD",
    "-702": "ERR_PKCS12_IMPORT_FAILED",
    "-708": "ERR_PKCS12_IMPORT_INVALID_FILE",
    "-707": "ERR_PKCS12_IMPORT_INVALID_MAC",
    "-709": "ERR_PKCS12_IMPORT_UNSUPPORTED",
    "-133": "ERR_PRECONNECT_MAX_SOCKET_LIMIT",
    "-712": "ERR_PRIVATE_KEY_EXPORT_FAILED",
    "-127": "ERR_PROXY_AUTH_REQUESTED",
    "-364": "ERR_PROXY_AUTH_REQUESTED_WITH_NO_CONNECTION",
    "-115": "ERR_PROXY_AUTH_UNSUPPORTED",
    "-136": "ERR_PROXY_CERTIFICATE_INVALID",
    "-130": "ERR_PROXY_CONNECTION_FAILED",
    "-366": "ERR_PROXY_HTTP_1_1_REQUIRED",
    "-380": "ERR_QUIC_CERT_ROOT_NOT_KNOWN",
    "-381": "ERR_QUIC_GOAWAY_REQUEST_CAN_BE_RETRIED",
    "-358": "ERR_QUIC_HANDSHAKE_FAILED",
    "-356": "ERR_QUIC_PROTOCOL_ERROR",
    "-174": "ERR_READ_IF_READY_NOT_IMPLEMENTED",
    "-328": "ERR_REQUEST_RANGE_NOT_SATISFIABLE",
    "-345": "ERR_RESPONSE_BODY_TOO_BIG_TO_DRAIN",
    "-349": "ERR_RESPONSE_HEADERS_MULTIPLE_CONTENT_DISPOSITION",
    "-346": "ERR_RESPONSE_HEADERS_MULTIPLE_CONTENT_LENGTH",
    "-350": "ERR_RESPONSE_HEADERS_MULTIPLE_LOCATION",
    "-325": "ERR_RESPONSE_HEADERS_TOO_BIG",
    "-357": "ERR_RESPONSE_HEADERS_TRUNCATED",
    "-713": "ERR_SELF_SIGNED_CERT_GENERATION_FAILED",
    "-23": "ERR_SOCKET_IS_CONNECTED",
    "-15": "ERR_SOCKET_NOT_CONNECTED",
    "-162": "ERR_SOCKET_RECEIVE_BUFFER_SIZE_UNCHANGEABLE",
    "-163": "ERR_SOCKET_SEND_BUFFER_SIZE_UNCHANGEABLE",
    "-160": "ERR_SOCKET_SET_RECEIVE_BUFFER_SIZE_ERROR",
    "-161": "ERR_SOCKET_SET_SEND_BUFFER_SIZE_ERROR",
    "-120": "ERR_SOCKS_CONNECTION_FAILED",
    "-121": "ERR_SOCKS_CONNECTION_HOST_UNREACHABLE",
    "-149": "ERR_SSL_BAD_PEER_PUBLIC_KEY",
    "-126": "ERR_SSL_BAD_RECORD_MAC_ALERT",
    "-164": "ERR_SSL_CLIENT_AUTH_CERT_BAD_FORMAT",
    "-110": "ERR_SSL_CLIENT_AUTH_CERT_NEEDED",
    "-135": "ERR_SSL_CLIENT_AUTH_CERT_NO_PRIVATE_KEY",
    "-177": "ERR_SSL_CLIENT_AUTH_NO_COMMON_ALGORITHMS",
    "-134": "ERR_SSL_CLIENT_AUTH_PRIVATE_KEY_ACCESS_DENIED",
    "-141": "ERR_SSL_CLIENT_AUTH_SIGNATURE_FAILED",
    "-125": "ERR_SSL_DECOMPRESSION_FAILURE_ALERT",
    "-153": "ERR_SSL_DECRYPT_ERROR_ALERT",
    "-148": "ERR_SSL_HANDSHAKE_NOT_COMPLETED",
    "-181": "ERR_SSL_KEY_USAGE_INCOMPATIBLE",
    "-123": "ERR_SSL_NO_RENEGOTIATION",
    "-172": "ERR_SSL_OBSOLETE_CIPHER",
    "-150": "ERR_SSL_PINNED_KEY_NOT_IN_CERT_CHAIN",
    "-107": "ERR_SSL_PROTOCOL_ERROR",
    "-114": "ERR_SSL_RENEGOTIATION_REQUESTED",
    "-167": "ERR_SSL_SERVER_CERT_BAD_FORMAT",
    "-156": "ERR_SSL_SERVER_CERT_CHANGED",
    "-159": "ERR_SSL_UNRECOGNIZED_NAME_ALERT",
    "-113": "ERR_SSL_VERSION_OR_CIPHER_MISMATCH",
    "-332": "ERR_SYN_REPLY_NOT_RECEIVED",
    "-139": "ERR_TEMPORARILY_THROTTLED",
    "-7": "ERR_TIMED_OUT",
    "-180": "ERR_TLS13_DOWNGRADE_DETECTED",
    "-382": "ERR_TOO_MANY_ACCEPT_CH_RESTARTS",
    "-310": "ERR_TOO_MANY_REDIRECTS",
    "-375": "ERR_TOO_MANY_RETRIES",
    "-506": "ERR_TRUST_TOKEN_OPERATION_FAILED",
    "-507": "ERR_TRUST_TOKEN_OPERATION_SUCCESS_WITHOUT_SENDING_REQUEST",
    "-111": "ERR_TUNNEL_CONNECTION_FAILED",
    "-170": "ERR_UNABLE_TO_REUSE_CONNECTION_FOR_PROXY_AUTH",
    "-344": "ERR_UNDOCUMENTED_SECURITY_LIBRARY_STATUS",
    "-9": "ERR_UNEXPECTED",
    "-323": "ERR_UNEXPECTED_PROXY_AUTH",
    "-342": "ERR_UNEXPECTED_SECURITY_LIBRARY_STATUS",
    "-302": "ERR_UNKNOWN_URL_SCHEME",
    "-334": "ERR_UNRECOGNIZED_FTP_DIRECTORY_LISTING_FORMAT",
    "-312": "ERR_UNSAFE_PORT",
    "-311": "ERR_UNSAFE_REDIRECT",
    "-339": "ERR_UNSUPPORTED_AUTH_SCHEME",
    "-14": "ERR_UPLOAD_FILE_CHANGED",
    "-25": "ERR_UPLOAD_STREAM_REWIND_NOT_SUPPORTED",
    "-124": "ERR_WINSOCK_UNEXPECTED_WRITTEN_BYTES",
    "-179": "ERR_WRONG_VERSION_ON_EARLY_DATA",
    "-145": "ERR_WS_PROTOCOL_ERROR",
    "-154": "ERR_WS_THROTTLE_QUEUE_TOO_LARGE",
    "-173": "ERR_WS_UPGRADE"
};

export { obfuscation, bareServerURL, github, discord, internalNamespace, jsNamespace, exposedInternalUrls, extensionsDisabled, networkErrors };
